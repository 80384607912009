import { useMutation, useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import {
  createUsers,
  deleteUsers,
  downloadUsers,
  enableDisableUsers,
  fetchUser,
  fetchUsers,
  resetUsersPassword,
  updateUser,
  fetchPartialUserPermissions,
  updateRolesAssignedToUsers,
} from './apiUsers';
import { handleError } from './helperFunctions';

export default function useUsers(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const usersQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS, userAccountKey];
  const rolesQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(usersQueryKey),
    reset: () => queryClient.resetQueries(usersQueryKey),
    fetchUsers: (params) => {
      const queryKeyFetchUsers = [...usersQueryKey];
      if (params && Object.values(params)?.length) {
        const paramsQueryKey = Object.values(params).filter((p) => p);
        if (paramsQueryKey.length) {
          queryKeyFetchUsers.push([...Object.values(params).filter((p) => p)]);
        }
      }
      return useQuery(queryKeyFetchUsers, () => fetchUsers(params), {
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      });
    },
    fetchUser: (userId) =>
      useQuery([...usersQueryKey, userId], () => fetchUser(userId), {
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchPartialUserPermissions: ({ paginationToken, search, actionId, category, userId }) =>
      useQuery(
        [
          ...usersQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          userId,
          category,
          actionId,
          paginationToken,
          search,
        ],
        () => fetchPartialUserPermissions(userId, category, actionId, paginationToken, search),
        {
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
          enabled: !isDisabled,
        },
      ),
    createUsers: useMutation(({ users }) => createUsers(users), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(usersQueryKey);
        await queryClient.invalidateQueries(rolesQueryKey);
      },
      onError: handleError,
    }),
    updateUser: useMutation(({ user }) => updateUser(user), {
      onSuccess: () => queryClient.invalidateQueries(usersQueryKey),
      onError: handleError,
    }),
    deleteUsers: useMutation(({ usersIds }) => deleteUsers(usersIds), {
      onSuccess: () => queryClient.invalidateQueries(usersQueryKey),
      onError: handleError,
    }),
    updateRolesAssignedToUsers: useMutation((updatePayload) => updateRolesAssignedToUsers(updatePayload), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(usersQueryKey);
        await queryClient.invalidateQueries(rolesQueryKey);
      },
      onError: handleError,
    }),
    enableDisableUsers: useMutation(({ usersIds, enable }) => enableDisableUsers(usersIds, enable), {
      onSuccess: () => queryClient.invalidateQueries(usersQueryKey),
      onError: handleError,
    }),
    resetUsersPassword: useMutation(({ usersIds, enable }) => resetUsersPassword(usersIds, enable), {
      onError: handleError,
    }),
    exportCsv: (selectedUsers) =>
      useQuery([...usersQueryKey, ...selectedUsers], () => downloadUsers(selectedUsers), {
        retry: false,
        staleTime: STALE_TIME,
      }),
  };
}
