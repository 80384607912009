import React from 'react';
import PropType from 'prop-types';
import { ReactComponent as NoUsers } from 'users/containers/Organization/Users/img/no-users.svg';

import styles from './UsersNoData.module.scss';

const UsersNoData = ({ colSpan, rowSpan }) => {
  return (
    <td colSpan={colSpan} rowSpan={rowSpan} className="border-bottom-0 border-start-0 border-end-0">
      <div className={styles.usersEmptyState}>
        <NoUsers />
        <div className={styles.emptyStateText}>
          <span className={styles.mainText}>No users found</span>
          <span className={styles.text}>Refine your filters to find the user you are looking for</span>
        </div>
      </div>
    </td>
  );
};

UsersNoData.propTypes = {
  colSpan: PropType.number,
  rowSpan: PropType.number,
};

export default UsersNoData;
