import React from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import AddRolesTable from './AddRolesTable';
import AddRolesHeader from './AddRolesHeader';

const AddRoles = ({ roles, pageChanged, search, setSearch, selectedRoles, onRolesAddedRemoved, isLoading }) => {
  const { NewTableWrapper } = useTable();

  return (
    <NewTableWrapper className="sub-table" isCompact>
      <AddRolesHeader
        pageChanged={pageChanged}
        selectedRolesCount={selectedRoles?.length}
        search={search}
        setSearch={setSearch}
        total={roles?.length}
      />
      <AddRolesTable
        roles={roles}
        selectedRoles={selectedRoles}
        onRolesAddedRemoved={onRolesAddedRemoved}
        isLoading={isLoading}
      />
    </NewTableWrapper>
  );
};

AddRoles.propTypes = {
  isLoading: PropTypes.bool,
  roles: PropTypes.array,
  pageChanged: PropTypes.func.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  selectedRoles: PropTypes.array,
  onRolesAddedRemoved: PropTypes.func.isRequired,
};

export default AddRoles;
