import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import Spinner from 'shared/components/andtComponents/Spinner';
import RoleUsersTabHeader from './RoleUsersTabHeader';
import RoleUsersTabTable from './RoleUsersTabTable';
import { doesUserMatchSearch } from '../../consts';

import styles from './RoleTab.module.scss';

const RoleUsersTab = ({ row }) => {
  const [roleUsers, setRoleUsers] = useState(row?.roles || []);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState();
  const [isInProgress, setIsInProgress] = useState(false);

  const { NewTableWrapper } = useTable();

  const { fetchUsers, updateRolesAssignedToUsers } = useUsers();

  const { data, isLoading, isFetching } = fetchUsers();

  useEffect(() => {
    setSelectedUsers([]);
  }, [data?.users]);

  useEffect(() => {
    if (data?.users) {
      const users = data.users.filter((u) => u.roles?.find((r) => r.id === row?.id));
      const searchedUsers = search ? users.filter((user) => doesUserMatchSearch(user, search)) : users;
      setRoleUsers(searchedUsers);
    }
  }, [data?.users, search]);

  const removeUsersClicked = async (users) => {
    setIsInProgress(true);
    await updateRolesAssignedToUsers.mutateAsync({
      roleIdsToRemove: [row.id],
      userIds: users.map((u) => u.id),
    });
    setIsInProgress(false);
  };

  const removeUsersListClicked = async () => {
    const users = roleUsers.filter((_, index) => selectedUsers.includes(index));
    return removeUsersClicked(users);
  };

  return isLoading || isFetching || isInProgress ? (
    <div className="position-relative">
      <Spinner />
    </div>
  ) : (
    <div className={styles.rolesSubTabTableContainer}>
      <NewTableWrapper className="sub-table" isCompact>
        <RoleUsersTabHeader
          removeUserClicked={removeUsersListClicked}
          rolesCount={roleUsers?.length}
          selectedUserRoles={selectedUsers}
          search={search}
          setSearch={setSearch}
        />
        <RoleUsersTabTable
          removeUserClicked={(user) => removeUsersClicked([user])}
          roleUsers={roleUsers}
          selectedUserRoles={selectedUsers}
          setSelectedUserRoles={setSelectedUsers}
        />
      </NewTableWrapper>
    </div>
  );
};

RoleUsersTab.propTypes = {
  row: PropTypes.object,
};

export default RoleUsersTab;
