import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isDecimalNeeded } from 'shared/utils/strUtil';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import moment from 'moment';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { dashLegend } from 'shared/components/chartComponents/Legends/LegendCommon';
import styles from './recommendedChart.module.scss';

const CustomChartTooltip = ({ active, payload, turnedOffLines, clickedLineId, tooltipMapData, purchaseEvents, expirationEvents }) => {
  const { currencySymbol } = useUserSettingsContext();
  const getTooltipFormat = (value) => {
    if (value === null || value === undefined) {
      return '';
    }
    return `${currencySymbol}${Number(value).toFixed(isDecimalNeeded(value, 2) ? 2 : 0)}`;
  };

  const getValuePercent = (value, percentOf) => `(${Number((value / percentOf) * 100).toFixed(0)}%)`;
  const getDateFormat = (date) => {
    const format = date?.includes(' ') ? 'MMM DD YYYY HH:mm' : 'MMM DD YYYY';
    return moment(date).format(format);
  };

  const turnedOnLines = useMemo(() => {
    let lines = payload?.filter((entry) => !turnedOffLines?.includes(`${entry.dataKey}Legend`));
    if (clickedLineId) {
      const clickedLine = lines?.filter((entry) => entry.id === clickedLineId);
      if (clickedLine) {
        lines = clickedLine;
      }
    }
    return lines;
  }, [payload, turnedOffLines, clickedLineId]);

  const calculateSumOfFields = (fields) =>
    turnedOnLines.filter((line) => fields.includes(line.dataKey))?.reduce((sum, entry) => sum + Number(entry.value), 0);
  const getTotalRowValue = (summeryRow) => {
    if (summeryRow.displayField) {
      return getTooltipFormat(payload[0].payload[summeryRow.displayField]);
    }
    if (summeryRow.totalFields) {
      const totalValue = calculateSumOfFields(summeryRow.totalFields);
      return getTooltipFormat(totalValue);
    }
    if (summeryRow.minusFields?.length) {
      const calculatedFields = summeryRow.minusFields.map((field) => {
        if (field.plusFields?.length) {
          const calculatedPlusFields = field.plusFields.map((field) => payload[0].payload[field]);
          return calculatedPlusFields.reduce((a, b) => Number(a) + Number(b));
        }
        return payload[0].payload[field];
      });
      return getTooltipFormat(calculatedFields.reduce((a, b) => Number(a) - Number(b)));
    }
    if (summeryRow.plusFields?.length) {
      const calculatedFields = summeryRow.plusFields.map((field) => payload[0].payload[field]);
      return getTooltipFormat(calculatedFields.reduce((a, b) => Number(a) + Number(b)));
    }
    if (summeryRow.key) {
      return getTooltipFormat(payload[0].payload[summeryRow.key]);
    }
    return '';
  };

  const eventInfo = (dataPoint) => {
    let eventText;
    if (purchaseEvents?.[dataPoint.date]) {
      const purchaseText = `${purchaseEvents?.[dataPoint.date]} Saving${purchaseEvents?.[dataPoint.date] > 1 ? 's' : ''} Plan purchased`;
      if(expirationEvents?.[dataPoint.date]) {
        eventText = `${purchaseText}, ${expirationEvents?.[dataPoint.date]} expired`;
      } else {
        eventText = purchaseText;
      }
    } else if(expirationEvents?.[dataPoint.date]) {
      eventText = `${expirationEvents?.[dataPoint.date]} Saving${purchaseEvents?.[dataPoint.date] > 1 ? 's' : ''} Plan expired`;
    }
    if(eventText) {
      return (
        <div className={styles.eventsWrapper}>
          <GenerateIcon iconName={ICONS.calendarStar.name} className={styles.eventIcon}/>
          <span>{eventText}</span>
        </div>
      );
    }
    return null;
  }
  const onDemandTooltip = (dataPoint) => {
    return (
      <div className={styles.customizedTooltip}>
        <div className={styles.tooltipTitleContainer}>
          <GenerateIcon iconName={ICONS.calendarDay.name} />
          <div className={styles.tooltipTitle}>{getDateFormat(payload[0].payload.date)}</div>
        </div>
        <div className={styles.tooltipDataContainer}>
          {eventInfo(dataPoint)}
          {tooltipMapData?.fields?.map((entry, index) => (
            <div className={styles.tooltipDataPair} key={index}>
              <div className={styles.tooltipDataCircle} style={{ backgroundColor: entry.color }} />
              <div className={`${styles.tooltipData} ${entry.isBold && styles.bold}`}>
                <span>{entry.name}</span>
                <span className={styles.value}>{`${getTooltipFormat(dataPoint[entry.key])}`}</span>
                <span className={styles.percent}> {entry.percentOf ? getValuePercent(dataPoint[entry.key], dataPoint[entry.percentOf]): ''}</span>
              </div>
            </div>
          ))}
          {tooltipMapData?.summary?.length && (
            <div className={styles.totalRowWrapper}>
              {tooltipMapData.summary.map((row, index) => (
                <div className={`${styles.totalRow} ${row.minusFields?.length && styles.specialRow}`} key={index}>
                  <span className={styles.totalData}>{row.name}</span>
                  <span className={styles.totalData}> {`${getTotalRowValue(row)}`}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const actualCostTooltip = (dataPoint) => {
    const sortedFields = tooltipMapData?.fields?.[0]?.subFields
      ? tooltipMapData?.fields
      : tooltipMapData?.fields;
    const getTooltipField = (entry, isLastField) => (
      <div className={`${styles.tooltipDataPair} ${isLastField && styles.actualTooltipData}`}>
        {entry.isDash ? (
          dashLegend(entry.color, entry.key)
        ) : (
          <div className={styles.tooltipDataCircle} style={{ backgroundColor: entry.color }} />
        )}

        <div className={`${styles.tooltipData} `}>
          <span>{entry.name}</span>
          <span>{`${getTooltipFormat(dataPoint[entry.key])}`}</span>
        </div>
      </div>
    );
    const getTooltipSubFields = (tpField) => (
      <>
        <span className={styles.groupHeader}>{tpField.header}</span>
        {tpField.subFields?.map((entry, index) => getTooltipField(entry, index === tpField.subFields.length - 1))}
      </>
    );
    return (
      <div className={styles.customizedTooltip}>
        <div className={styles.tooltipTitleContainer}>
          <GenerateIcon iconName={ICONS.calendarDay.name} />
          <div className={styles.tooltipTitle}>{getDateFormat(payload[0].payload.date)}</div>
        </div>
        <div className={`${styles.tooltipDataContainer} ${styles.actualTpContainer}`}>
          {eventInfo(dataPoint)}
          {sortedFields?.map((entry) => {
            if (entry.subFields) {
              return getTooltipSubFields(entry);
            }
            return getTooltipField(entry, true);
          })}
          {tooltipMapData?.summary?.length && (
            <div className={styles.totalRowWrapper}>
              {tooltipMapData.summary.map((row, index) => (
                <div className={`${styles.totalRow} ${row.minusFields?.length && styles.specialRow}`} key={index}>
                  <span className={styles.totalData}>{row.name}</span>
                  <span className={styles.totalData}> {`${getTotalRowValue(row)}`}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  if (!active || !payload || !payload.length) {
    return null;
  }
  const dataPoint = payload[0]?.payload;
  if (tooltipMapData.type === 'onDemand') {
    return onDemandTooltip(dataPoint);
  }
  return actualCostTooltip(dataPoint);
};

CustomChartTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.array,
  turnedOffLines: PropTypes.array,
  clickedLineId: PropTypes.string,
  purchaseEvents: PropTypes.object,
  expirationEvents: PropTypes.object,
  tooltipMapData: PropTypes.object.isRequired,
};

export default CustomChartTooltip;
