  // We need to wait for each step to finish before moving on to the next
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ProgressLoader from 'shared/components/andtComponents/ProgressLoader';
import loaderStep1 from '../img/sp-loader-step1.gif';
import loaderStep2 from '../img/sp-loader-step2.gif';
import loaderStep3 from '../img/sp-loader-step3.gif';
import loaderStep4 from '../img/sp-loader-step4.gif';
import styles from './recommendedPlanData.module.scss';
import SpSpecialMode from './SpSpecialMode';

const STEPS_DURATIONS = [4000, 6000, 6000, 1000];

export const SpLoader = ({ dataReceived, setIsLoading }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showReadyStep, setShowReadyStep] = useState(false);
  const timeoutIds = useRef([]);

  const nextStep = (currStepIdx, duration) =>
    new Promise((resolve) => {
      timeoutIds.current.push(
        setTimeout(() => {
          if (currStepIdx === STEPS_DURATIONS.length - 1) {
            setShowReadyStep(true);
            return setTimeout(() => setIsLoading(false), 500);
          }
          setCurrentStep(currStepIdx + 1);
          return resolve();
        }, duration),
      );
    });

  useEffect(() => {
    setIsLoading(true);
    // Create random durations according to the median durations +- 25%
    const randomDurations = STEPS_DURATIONS.map((medianDuration) => (Math.random() / 2 + 0.75) * medianDuration);
    (async () => {
      for (let i = 0; i < randomDurations.length; i++) {
        await nextStep(i, randomDurations[i]);
      }
    })();

    return () => timeoutIds.current.forEach((id) => clearTimeout(id));
  }, []);

  useEffect(() => {
    if (dataReceived) {
      // Clear all timeouts and initiate realistic completion of all steps
      timeoutIds.current.forEach((id) => clearTimeout(id));
      (async () => {
        for (let i = currentStep; i < STEPS_DURATIONS.length; i++) {
          const duration = Math.random() * 1300 + 1000; // Short duration of 1000-2300ms
          await nextStep(i, duration);
        }
      })();
    }
  }, [dataReceived]);
  return !showReadyStep ? (
    <ProgressLoader
      steps={[
        {
          description: 'Processing your Savings Plan preferences',
          image: <img src={loaderStep1} alt="loader-step-1" />,
        },
        {
          description: 'Retrieving your Cost & Usage data',
          image: <img src={loaderStep2} alt="loader-step-2" />,
        },
        {
          description: 'Allocating costs using advanced algorithms',
          image: <img src={loaderStep3} alt="loader-step-3" />,
        },
        {
          description: 'Generating Savings Plan recommendations',
          image: <img src={loaderStep4} alt="loader-step-4" />,
        },
      ]}
      currentStep={currentStep}
      className={styles.loaderPadding}
    />
  ) : (
    <SpSpecialMode specialText="Your Savings Plan Recommendations are ready!" isLoading={true} />
  );
};

SpLoader.propTypes = {
  dataReceived: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
