import React, { useEffect, useState } from 'react';
import { useSpAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import AnalyzerFilters from './AnalyzerFilters';
import RecommendedPlanData from './RecommendedPlanData';
import SpSpecialMode from './SpSpecialMode';
import NoteModal from 'shared/components/noteModal/NoteModal';
import styles from './recommendedPlanData.module.scss';
import { SpLoader } from './SpLoader';
const RecommendedPlan = () => {
  const { appStore } = useRootStore();
  const [isShowNoteMsg, setIsShowNoteMsg] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const {
    setHourlyCommitment,
    recommendationPlanData,
    isLoading,
    refetch,
    isPreferencesLoading,
    isFetching,
    setPlanDataByKey,
    setModifiedPlanKeyHourlyCommitment,
  } = useSpAnalyzerContext();

  useEffect(() => {
    if (appStore.isCustomerPricingMode) {
      setIsShowNoteMsg(true);
    }
  }, [appStore.isCustomerPricingMode]);
  const isPlanDataEmpty = () => {
    if (!recommendationPlanData || Object.keys(recommendationPlanData).length === 0) {
      return true;
    }
    return !!(recommendationPlanData.recommendationPerPlan && Object.values(recommendationPlanData.recommendationPerPlan).every((value) => value === null));
  };
  const componentToRender = () => {
    if (isPreferencesLoading || isLoading || isFetching || isShowLoader) {
      return (
        <div className={styles.loaderWrapper}>
          <SpLoader dataReceived={!isPlanDataEmpty()} setIsLoading={setIsShowLoader} />
        </div>
      );
    }
    if (isPlanDataEmpty()) {
      return (
        <SpSpecialMode
          isEmpty
          specialText={
            <>
              <span>Based on the selected filters, your expenses are already well-covered by savings plans.</span>
              <span>Therefore, we don’t have any new plans to recommend.</span>
            </>
          }
          isLongText
          type="emptyState"
        />
      );
    }
    return <RecommendedPlanData />;
  };
  return (
    <div>
      <AnalyzerFilters
        analyze={() => {
          setHourlyCommitment(null);
          setPlanDataByKey({});
          setModifiedPlanKeyHourlyCommitment({});
          refetch();
        }}
        disabled={isLoading || isFetching}
      />
      {componentToRender()}
      {isShowNoteMsg && (
        <NoteModal
          close={() => setIsShowNoteMsg(false)}
          btnText="Got it"
          message={
            <>
              For best results, use the SP Analyzer in <b>Partner Pricing mode</b> or within the <b>Customer scope.</b>
            </>
          }
        />
      )}
    </div>
  );
};

export default RecommendedPlan;
