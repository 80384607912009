import React, { useEffect, useState } from 'react';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import Pagination from 'shared/components/andtComponents/Pagination';
import { palette } from 'shared/constants/colorsConstants';
import UsersIcon from './UsersIcon';
import AddUsersModal from './userModals/AddUsersModal/AddUsersModal';
import { useUsersContext } from './contexts/usersContext';
import { Search } from '../components/Search';
import { DEFAULT_PAGE_SIZE } from '../consts';

import styles from './UsersHeader.module.scss';

const UsersHeader = ({ pageChanged, search, setSearch, total, userStatusFilterChange, userStatusFilter }) => {
  const [filterByEnabled, setFilterByEnabled] = useState(userStatusFilter === true);
  const [filterByDisabled, setFilterByDisabled] = useState(userStatusFilter === false);

  const { showFilters, setShowFilters, isAllExpanded, setIsAllExpanded } = useUsersContext();

  const [isCsvDataFetching] = useState(false);

  // const usersHook = useUsers();
  // const { data: usersForCSV, isLoading } = usersHook.fetchAllUsers();

  const fetchExportData = () => {};

  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = total >= 0 ? Math.ceil(total / DEFAULT_PAGE_SIZE) : 0;

  useEffect(() => {
    if ((!filterByEnabled && !filterByDisabled) || (filterByEnabled && filterByDisabled)) {
      userStatusFilterChange(null);
    } else if (filterByEnabled) {
      userStatusFilterChange(true);
    } else if (filterByDisabled) {
      userStatusFilterChange(false);
    }
  }, [filterByEnabled, filterByDisabled]);

  const onPageChange = (newPage) => {
    pageChanged(newPage - currentPage);
    setCurrentPage(newPage);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.expandAndTitle}>
          <Button
            automationId="expand-rows"
            text=""
            isSecondary
            icon={() => <GenerateIcon iconName={isAllExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
            onClick={() => {
              setIsAllExpanded(!isAllExpanded);
            }}
          />
          <div className={styles.header}>
            <UsersIcon />
            <div className={styles.title}>
              Users <span className={styles.count}>({total})</span>
            </div>
          </div>
        </div>
        <div className={styles.filters}>
          <Checkbox
            className={styles.overrideCheckboxLabel}
            isChecked={filterByEnabled}
            onChange={() => {
              setFilterByEnabled(!filterByEnabled);
            }}
            primary
            text="Enabled"
          />
          <Checkbox
            className={styles.overrideCheckboxLabel}
            isChecked={filterByDisabled}
            primary
            onChange={() => {
              setFilterByDisabled(!filterByDisabled);
            }}
            text="Disabled"
          />
        </div>
      </div>
      <div className={styles.headerRight}>
        <AddUsersModal />
        <Search search={search} setSearch={setSearch} isDebounce />
        <Tooltip title="Filter columns value" arrow>
          <Button
            text="Filter Results"
            automationId="users-filter-results"
            onClick={() => {
              setShowFilters(!showFilters);
            }}
            overrideStyles={showFilters ? { background: palette.blue[200] } : {}}
            isSecondary
            icon={() => (
              <GenerateIcon
                iconName={showFilters ? ICONS.xMark.name : ICONS.filterList.name}
                className={styles.filtersIcon}
              />
            )}
          />
          <span />
        </Tooltip>
        <Tooltip title="Export" arrow>
          <CustomCSVDownload
            fetchData={fetchExportData}
            automationId="export-recommendations"
            showDownloadIcon
            isLoading={isCsvDataFetching}
            filesNumber={1}
            isSecondary
            isLightButton
            hideText
            style={{ height: 36 }}
          />
          <span />
        </Tooltip>
        <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default UsersHeader;
