import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  FILTER_NUMBER_OR_DATE_OPERATIONS,
  FILTER_OPERATIONS,
} from 'shared/components/andtComponents/TableComponents/const';
import {
  CustomFilterCell,
  CustomFilterIcon,
  CustomFilterRow,
  CustomFormatterWithTooltipAndComponent,
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import UsersIcon from './UsersIcon';
import { useUsersContext } from './contexts/usersContext';
import { getColumns, getDefaultSorting, getSortingColumns, USERS_COLUMNS } from '../consts';
import UsersBulkOperations from './UsersBulkOperations';
import UserExpandedData from './usersExpandedRowComponents/UserExpandedData';
import UserActions from './UserActions';
import UserImpersonate from './usersTableComponents/UserImpersonate';
import UserStatusSwitchButton from '../components/UserStatusSwitchButton';
import UserRoles from './usersTableComponents/UserRoles';

import styles from './UsersTable.module.scss';
import UsersNoData from '~/users/containers/Organization/components/EmptyStates/UsersNoData.jsx';

const UsersTable = ({ users }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(USERS_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const rightAlignedColumns = [
    USERS_COLUMNS.USER_STATUS.columnName,
    USERS_COLUMNS.IMPERSONATE.columnName,
    USERS_COLUMNS.ACTIONS.columnName,
  ];

  const { NewTableRow, NewTableSelectionBar, CustomToggleCell } = useTable();

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  // const [sorting, setSorting] = useState([{ columnName: USERS_COLUMNS.USER_NAME.columnName, direction: 'asc' }]);

  const { showFilters, setTableFilters, selectedRows, setSelectedRows, isAllExpanded, setIsAllExpanded } =
    useUsersContext();

  const updateFilters = (filters) => {
    const tempFilters = {};
    filters.forEach((f) => {
      tempFilters[f.columnName] = { like: [f.value] };
    });
    setTableFilters(tempFilters);
  };

  const debouncedSetFilters = debounce(updateFilters, 500);

  const onFiltersChange = (newFilters) => {
    debouncedSetFilters(newFilters);
  };

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(users?.map((row, index) => index));
    }
  }, [isAllExpanded]);

  useEffect(() => {
    if (expandedRowIds?.length === 0) {
      setIsAllExpanded(false);
    }
  }, [expandedRowIds]);

  return (
    users && (
      <Grid rows={users} columns={getColumns(USERS_COLUMNS)} automationId="users-list">
        <SortingState
          columnExtensions={getSortingColumns(USERS_COLUMNS)}
          defaultSorting={getDefaultSorting(USERS_COLUMNS)}
        />
        {showFilters && <FilteringState onFiltersChange={onFiltersChange} />}
        <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
        <IntegratedSelection />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <DataTypeProvider
          for={[USERS_COLUMNS.USER_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent>
              <UsersIcon />
            </CustomFormatterWithTooltipAndComponent>
          )}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.FIRST_NAME.columnName]}
          formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.LAST_NAME.columnName]}
          formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.ROLES.columnName]}
          formatterComponent={UserRoles}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.CREATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText
              value={props?.row?.createdAt ? moment(props?.row?.createdAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.LAST_LOGIN.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText
              value={props?.row?.lastLogin ? moment(props?.row?.lastLogin).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider for={[USERS_COLUMNS.USER_STATUS.columnName]} formatterComponent={UserStatusSwitchButton} />
        <DataTypeProvider for={[USERS_COLUMNS.IMPERSONATE.columnName]} formatterComponent={UserImpersonate} />
        <DataTypeProvider for={[USERS_COLUMNS.ACTIONS.columnName]} formatterComponent={UserActions} />
        <TableWrapper
          virtual
          noDataCellComponent={UsersNoData}
          rowComponent={(props) => (
            <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
          )}
          height="auto"
        />
        <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableRowDetail contentComponent={UserExpandedData} toggleCellComponent={CustomToggleCell} />
        <TableHeaderRow cellComponent={CustomHeaderCell} />
        {showFilters && (
          <TableFilterRow
            showFilterSelector
            iconComponent={CustomFilterIcon}
            rowComponent={CustomFilterRow}
            cellComponent={(props) => <CustomFilterCell {...props} actionColumns={rightAlignedColumns} />}
          />
        )}
        <NewTableSelectionBar selectedRows={selectedRows} counterClass={styles.counterClass}>
          <UsersBulkOperations users={users} />
        </NewTableSelectionBar>
      </Grid>
    )
  );
};

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UsersTable;
