import React from 'react';
import styles from './planPreferences.module.scss';

export const PlanPreferences = () => {
  return (
    <div className={styles.preferences}>
        <div className={styles.flexWrapper}>
            <span>MY PREFERENCES</span>
        </div>
    </div>
  );
};
