import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import React from 'react';

import styles from './Roles.module.scss';

const RolesIcon = () => (
  <div className={styles.rolesIcon}>
    <GenerateIcon iconName={ICONS.userGearSolid.name} className={styles.icon} />
  </div>
);

export default RolesIcon;
