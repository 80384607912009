import React from 'react';
import PropTypes from 'prop-types';
import TabsSelector from 'shared/components/andtComponents/TabsSelector/TabsSelector';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import UserRolesTab from './UserRolesTab';
import UserEffectivePermissionsTab from './UserEffectivePermissionsTab';

const USER_ROW_TABS = {
  ROLES: {
    iconName: ICONS.userGear.name,
    label: 'Roles',
    id: 'roles',
    isSelected: true,
    getComponent: (row) => <UserRolesTab row={row} />,
  },
  EFFECTIVE_PERMISSIONS: {
    iconName: ICONS.clipboard.name,
    label: 'Effective Permissions',
    id: 'effectivePermissions',
    getComponent: (row) => <UserEffectivePermissionsTab row={row} />,
  },
};

const UserExpandedData = ({ row }) => <TabsSelector tabsInfo={Object.values(USER_ROW_TABS)} row={row} />;

UserExpandedData.propTypes = {
  row: PropTypes.object.isRequired,
};

export default UserExpandedData;
