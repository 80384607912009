import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import DailyHourlySwitchButton from './DailyHourlySwitchButton';
import styles from './recommendedChart.module.scss';
import DownloadChartMenu from './DownloadChartMenu';

const sparklesIcon = () => <GenerateIcon iconName={ICONS.sparkles.name} className={styles.sparklesIcon} />;
const ChartHeader = ({
  header,
  info,
  exportToCsv,
  exportToPng,
  exportToSvg,
  includeEstimatedOption,
  isDailyMode,
  setIsDailyMode,
  isEstimatedMode,
  setIsEstimatedMode,
  automationId,
  showEvents,
  setShowEvents,
  includeEvents
}) => (
  <div className={styles.headerWrapper}>
    <div className={styles.flex}>
      <span className={styles.header}>{header}</span>
      <Tooltip arrow title={info}>
        <span>
          <GenerateIcon iconName={ICONS.circleInfo.name} className={styles.icon} />
        </span>
      </Tooltip>
      <DailyHourlySwitchButton isDailyMode={isDailyMode} setIsDailyMode={setIsDailyMode} automationId={automationId} />
    </div>
    <div className={styles.flex}>
      {includeEvents && (<Checkbox
          text="Show Events"
          isChecked={showEvents}
          primary
          onChange={() => setShowEvents(!showEvents)}
      />)}
      <DownloadChartMenu exportToCsv={exportToCsv} exportToPng={exportToPng} exportToSvg={exportToSvg} />
      {includeEstimatedOption && (
        <Button
          text={
            <Checkbox
              className={styles.checkbox}
              text="Estimated Cost"
              isChecked={!!isEstimatedMode}
              onChange={() => setIsEstimatedMode(!isEstimatedMode)}
              noPaddings
            />
          }
          icon={sparklesIcon}
          iconPlacement="right"
        />
      )}
    </div>
  </div>
);

ChartHeader.propTypes = {
  header: PropTypes.string.isRequired,
  info: PropTypes.string,
  exportToCsv: PropTypes.func,
  exportToPng: PropTypes.func,
  exportToSvg: PropTypes.func,
  includeEstimatedOption: PropTypes.bool,
  isDailyMode: PropTypes.bool,
  setIsDailyMode: PropTypes.func,
  isEstimatedMode: PropTypes.bool,
  setIsEstimatedMode: PropTypes.func,
  automationId: PropTypes.string,
  showEvents: PropTypes.bool,
  setShowEvents: PropTypes.func,
  includeEvents: PropTypes.bool
};

export default ChartHeader;
