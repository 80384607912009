import React from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import LinkedAccountsTable from './LinkedAccountsTable';

import styles from './LinkedAccountsComponent.module.scss';

const LinkedAccounts = ({
  accountsData,
  additionalData,
  cloudProviderId,
  isCreateEditMode,
  payerAccount,
  linkedAccountsColumns,
  onRemoveLinkedAccount,
  onSelectionChange,
  modifiedSelectionById,
}) => {
  const { NewTableWrapper } = useTable();

  return (
    <div className={styles.linkedAccounts}>
      <NewTableWrapper className="sub-table" isCompact>
        <LinkedAccountsTable
          accountsData={accountsData}
          additionalData={additionalData}
          cloudProviderId={cloudProviderId}
          isCreateEditMode={isCreateEditMode}
          linkedAccountsColumns={linkedAccountsColumns}
          modifiedSelectionById={modifiedSelectionById}
          onRemoveLinkedAccount={onRemoveLinkedAccount}
          onSelectionChange={onSelectionChange}
          payerAccount={payerAccount}
        />
      </NewTableWrapper>
    </div>
  );
};

export default LinkedAccounts;
