import React, { useEffect, useMemo, useState } from 'react';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LinkedAccountsComponent from './LinkedAccountsComponent';
import { ACCOUNT_TYPES } from 'users/containers/Organization/consts.js';

const ENABLE_LOGS = false;

function debugLog(...args) {
  if (ENABLE_LOGS === true) {
    console.log('LinkedAccountsComponentWrapper.jsx:', ...args);
  }
}

const LinkedAccountsComponentWrapper = ({
  additionalData,
  allAccountsData,
  isCreateEditMode,
  linkedAccountsColumns,
  onRemoveLinkedAccount,
  onSelectionChange,
  payerAccountsColumns,
}) => {
  const accountsDataByCloudProviders = useMemo(() => {
    const data = { [CLOUD_TYPE_IDS.AWS]: [], [CLOUD_TYPE_IDS.AZURE]: [], [CLOUD_TYPE_IDS.GCP]: [] };
    if (allAccountsData) {
      allAccountsData?.accountsData?.forEach((account) => {
        switch (+account.payerAccount.cloudType) {
          case CLOUD_TYPE_IDS.AWS:
            data[CLOUD_TYPE_IDS.AWS].push(account);
            break;
          case CLOUD_TYPE_IDS.AZURE:
            data[CLOUD_TYPE_IDS.AZURE].push(account);
            break;
          case CLOUD_TYPE_IDS.GCP:
            data[CLOUD_TYPE_IDS.GCP].push(account);
            break;
          default:
            break;
        }
      });
    }
    return { ...allAccountsData, accountsData: data };
  }, [allAccountsData]);

  const [modifiedSelectionById, setModifiedSelectionById] = useState({});
  const [allAccountsDataUpdated, setAllAccountsDataUpdated] = useState(null);

  useEffect(() => {
    setAllAccountsDataUpdated(JSON.parse(JSON.stringify(accountsDataByCloudProviders)));
  }, [accountsDataByCloudProviders]);

  function updateModifiedSelection(newModifiedSelectionById, account, allSelectedIds, childAccounts) {
    const isSelectedNew = allSelectedIds?.includes(account.id);
    const isSelected = newModifiedSelectionById[account.id] ?? account.isSelectedInitial;
    if (isSelectedNew !== isSelected && account.isSelectionEnabled !== false) {
      debugLog('[updateModifiedSelection] isSelected do not match:', account, 'allSelectedIds:', allSelectedIds);
      if (modifiedSelectionById[account.id] === undefined) {
        newModifiedSelectionById[account.id] = isSelectedNew;
        debugLog('[updateModifiedSelection] newModifiedSelectionById value:', isSelectedNew);
      } else {
        delete newModifiedSelectionById[account.id];
        debugLog('[updateModifiedSelection] deleted newModifiedSelectionById value:', account.id);
      }

      // if payer account is modified by user, we reset all linked accounts modifications
      childAccounts?.forEach((childAccount) => {
        delete newModifiedSelectionById[childAccount.id];
      });
    }
  }

  const selectionPayload = useMemo(() => {
    if (!allAccountsData?.accountsData) {
      return null;
    }

    const payload = {
      accountsToAdd: {
        fullyAssignedPayerAccounts: [],
        payerWithLinkedAccounts: {},
      },
      accountsToRemove: {
        fullyAssignedPayerAccounts: [],
        payerWithLinkedAccounts: {},
      },
    };

    allAccountsData?.accountsData.forEach((account) => {
      const { payerAccount, linkedAccounts } = account;
      const modifiedPayerSelection = modifiedSelectionById[payerAccount.id];
      if (modifiedPayerSelection !== undefined) {
        if (modifiedPayerSelection === true) {
          payload.accountsToAdd.fullyAssignedPayerAccounts.push(payerAccount.id);
        } else {
          payload.accountsToRemove.fullyAssignedPayerAccounts.push(payerAccount.id);
        }
      } else {
        linkedAccounts.forEach((linkedAccount) => {
          const modifiedLinkedSelection = modifiedSelectionById[linkedAccount.id];
          if (modifiedLinkedSelection !== undefined) {
            if (modifiedLinkedSelection === true) {
              if (payload.accountsToAdd.payerWithLinkedAccounts[payerAccount.id] === undefined) {
                payload.accountsToAdd.payerWithLinkedAccounts[payerAccount.id] = [];
              }
              payload.accountsToAdd.payerWithLinkedAccounts[payerAccount.id].push(linkedAccount.id);
            } else {
              if (payload.accountsToRemove.payerWithLinkedAccounts[payerAccount.id] === undefined) {
                payload.accountsToRemove.payerWithLinkedAccounts[payerAccount.id] = [];
              }
              payload.accountsToRemove.payerWithLinkedAccounts[payerAccount.id].push(linkedAccount.id);
            }
          }
        });
      }
    });
    return payload;
  }, [modifiedSelectionById, allAccountsData]);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectionPayload);
    }
  }, [selectionPayload]);

  const handleSelectionChange = ({ type, allSelectedIds, parentPayerAccount }) => {
    debugLog(
      '[handleSelectionChange] Invoked with type:',
      type,
      'allSelectedIds:',
      allSelectedIds,
      'parentPayerAccount:',
      parentPayerAccount,
    );

    const newModifiedSelectionById = { ...modifiedSelectionById };
    allAccountsData?.accountsData.forEach((account) => {
      const { payerAccount, linkedAccounts } = account;
      if (type === ACCOUNT_TYPES.PAYER_ACCOUNT) {
        updateModifiedSelection(newModifiedSelectionById, payerAccount, allSelectedIds, linkedAccounts);
      } else if (type === ACCOUNT_TYPES.LINKED_ACCOUNT && parentPayerAccount?.id === payerAccount.id) {
        linkedAccounts?.forEach((linkedAccount) =>
          updateModifiedSelection(newModifiedSelectionById, linkedAccount, allSelectedIds),
        );
      }
    });
    setModifiedSelectionById(newModifiedSelectionById);

    debugLog('[handleSelectionChange] modifiedSelectionById:', modifiedSelectionById);
  };

  const handleRemoveLinkedAccount = (accountToRemove, type, payerId) => {
    let payload = {};

    if (type) {
      payload = {
        accountsToRemove: {
          fullyAssignedPayerAccounts: [],
          payerWithLinkedAccounts: {},
        },
      };
      if (type === ACCOUNT_TYPES.PAYER_ACCOUNT) {
        payload.accountsToRemove.fullyAssignedPayerAccounts.push(accountToRemove.id);
      } else if (type === ACCOUNT_TYPES.LINKED_ACCOUNT) {
        payload.accountsToRemove.payerWithLinkedAccounts[payerId] = [accountToRemove.id];
      }
    } else {
      // If removing from the Linked Accounts Component header - need to create a payload for BE api
      payload = selectionPayload;
    }

    onRemoveLinkedAccount(payload);
  };

  return (
    accountsDataByCloudProviders && (
      <LinkedAccountsComponent
        additionalData={additionalData}
        allAccountsData={allAccountsDataUpdated}
        isCreateEditMode={isCreateEditMode}
        linkedAccountsColumns={linkedAccountsColumns}
        onSelectionChange={handleSelectionChange}
        onRemoveLinkedAccount={handleRemoveLinkedAccount}
        payerAccountsColumns={payerAccountsColumns}
        modifiedSelectionById={modifiedSelectionById}
      />
    )
  );
};

export default LinkedAccountsComponentWrapper;
