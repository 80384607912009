import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { PAGINATION_DIRECTION } from '../../../consts';
import AddRoles from '../../../components/AddRoles/AddRoles';

import styles from './CreateRoleModal.module.scss';

const RolesStep = ({ role, setRole, roleValidationErrors, saveClicked }) => {
  const [selectedRoles, setSelectedRoles] = useState(role?.subRoles || []);
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);

  const { fetchRoles } = useRoles();
  const { data, isLoading } = fetchRoles({ paginationToken, search });

  useEffect(() => {
    setSelectedRoles(() => role?.subRoles || []);
  }, [data?.roles, role]);

  useEffect(() => {
    if (selectedRoles?.length) {
      setRole((prev) => ({ ...prev, subRoles: [...selectedRoles] }));
    } else {
      setRole((prev) => ({ ...prev, subRoles: [] }));
    }
  }, [selectedRoles]);

  const handleRolesAddedRemoved = ({ addedRoles, removedRoles }) => {
    setSelectedRoles((prev) => {
      const newSelectedRoles = prev.filter((role) => !removedRoles.find((r) => r.id === role.id));
      const combined = [...newSelectedRoles, ...addedRoles];
      return Array.from(new Map(combined.map((item) => [item.id, item])).values());
    });
  };

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  return (
    <>
      <div className={styles.rolesNotSelectedWarning}>
        {saveClicked && roleValidationErrors?.subRoles ? roleValidationErrors?.subRoles : ''}
      </div>
      <AddRoles
        isLoading={isLoading}
        roles={data?.roles}
        search={search}
        setSearch={setSearch}
        pageChanged={pageChanged}
        selectedRoles={selectedRoles}
        onRolesAddedRemoved={handleRolesAddedRemoved}
      />
    </>
  );
};

RolesStep.propTypes = {
  role: PropTypes.object,
  setRole: PropTypes.func.isRequired,
  roleValidationErrors: PropTypes.object,
  saveClicked: PropTypes.bool,
};

export default RolesStep;
