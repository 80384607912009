import React, { useCallback, useMemo } from 'react';
import { Grid, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { CustomSelectionCell } from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import { ACCOUNT_TYPES, getColumns, ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS } from '../../consts';
import RemoveButton from '../RemoveButton';
import LinkedAccounts from './LinkedAccounts';
import RowIconNameAndText from './RowIconNameAndText';
import LinkedAccountInfoIcon from './LinkedAccountInfoIcon';
import AssignedBy from './AssignedBy';
import {
  countSelectedLinkedAccounts,
  getAccountRemoveOrInfo,
  getIsAccountSelected,
  getPayerIsSelectionEnabled,
  STATES,
} from './dataAccessHelperFunctions';
import NoAccounts from './NoAccounts.jsx';

const PayerAccountsTable = ({
  accountsData,
  additionalData,
  cloudProviderId,
  expandedRows,
  isCreateEditMode,
  linkedAccountsColumns,
  modifiedSelectionById,
  onSelectionChange,
  onExpandedRowsChange,
  onRemoveLinkedAccount,
  payerAccountsColumns,
}) => {
  const expandedRowsIndexes = useMemo(
    () =>
      accountsData
        ?.map((payerAccount, index) => (expandedRows?.includes(payerAccount.payerAccount.id) ? index : -1))
        ?.filter((index) => index !== -1),
    [expandedRows],
  );

  const selectedRowIndexes = useMemo(
    () =>
      accountsData
        ?.map((pa, index) => (getIsAccountSelected(pa.payerAccount, modifiedSelectionById) ? index : -1))
        .filter((index) => index !== -1),
    [accountsData, modifiedSelectionById],
  );

  const handleExpandedRowIdsChange = (expandedRowsIndexes) => {
    const expandedRowsIds = accountsData
      .filter((pa, index) => expandedRowsIndexes.includes(index))
      .map((pa) => pa.payerAccount?.id);
    onExpandedRowsChange(cloudProviderId, expandedRowsIds);
  };

  const handleSelectedRowsChanged = (selectedRowIndexes) => {
    const selectedPayerAccountsIds = accountsData
      .filter((pa, index) => selectedRowIndexes.includes(index))
      .map((pa) => pa.payerAccount.id);

    onSelectionChange({ type: ACCOUNT_TYPES.PAYER_ACCOUNT, allSelectedIds: selectedPayerAccountsIds });
  };

  const { NewTableRow, CustomToggleCell } = useTable();

  const renderRemoveButton = ({ row }) => {
    if (row.payerAccount) {
      const removeOrInfo = getAccountRemoveOrInfo(row.payerAccount, isCreateEditMode);
      if (removeOrInfo === STATES.REMOVE) {
        return (
          <RemoveButton removeClicked={() => onRemoveLinkedAccount?.(row.payerAccount, ACCOUNT_TYPES.PAYER_ACCOUNT)} />
        );
      }
      if (removeOrInfo === STATES.INFO) {
        return <LinkedAccountInfoIcon row={row.payerAccount} />;
      }
    }
    return null;
  };

  const renderCustomSelectionCell = (props) => {
    const { row } = props;

    const isSelected = getIsAccountSelected(row.payerAccount, modifiedSelectionById);
    const isEnabled = getPayerIsSelectionEnabled(row.payerAccount, isCreateEditMode);

    return <CustomSelectionCell {...{ ...props, selected: isSelected }} isDisabled={!isEnabled} />;
  };

  const renderAssignedBy = ({ row }) => {
    if (row?.payerAccount) {
      return (
        <AssignedBy
          additionalData={additionalData}
          isCreateEditMode={isCreateEditMode}
          modifiedSelectionById={modifiedSelectionById}
          payerAccount={row.payerAccount}
        />
      );
    }
    return null;
  };

  const renderRowIconNameAndText = ({ row }) => {
    const cloudTypeId = row?.payerAccount?.cloudType;
    const title = row?.payerAccount?.name;

    const totalSelected = countSelectedLinkedAccounts([row], modifiedSelectionById);

    const subTitle = `(${totalSelected}/${row.totalPayerLinkedAccounts})`;

    return <RowIconNameAndText cloudType={+cloudTypeId} title={title} subTitle={subTitle} />;
  };

  const renderLinkedAccounts = useCallback(
    ({ row }) => (
      <LinkedAccounts
        accountsData={row?.linkedAccounts}
        additionalData={additionalData}
        cloudProviderId={cloudProviderId}
        isCreateEditMode={isCreateEditMode}
        payerAccount={row?.payerAccount}
        linkedAccountsColumns={linkedAccountsColumns}
        modifiedSelectionById={modifiedSelectionById}
        onRemoveLinkedAccount={onRemoveLinkedAccount}
        onSelectionChange={onSelectionChange}
      />
    ),
    [
      accountsData,
      additionalData,
      isCreateEditMode,
      linkedAccountsColumns,
      modifiedSelectionById,
      onRemoveLinkedAccount,
      onSelectionChange,
    ],
  );

  const renderPayerAccountTableRow = useCallback(
    (props) => (
      <NewTableRow expandedRowIds={expandedRowsIndexes} setExpandedRowIds={handleExpandedRowIdsChange} {...props} />
    ),
    [expandedRowsIndexes],
  );

  return (
    <Grid
      rows={accountsData}
      columns={getColumns(ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS)}
      automationId="role-payer-accounts-data-access"
    >
      <SelectionState selection={selectedRowIndexes} onSelectionChange={handleSelectedRowsChanged} />
      <IntegratedSelection />
      <RowDetailState expandedRowIds={expandedRowsIndexes} onExpandedRowIdsChange={handleExpandedRowIdsChange} />
      <DataTypeProvider
        for={[ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.PAYER_ACCOUNT.columnName]}
        formatterComponent={renderRowIconNameAndText}
      />
      {payerAccountsColumns.find((c) => c === ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.ASSIGNED_BY.columnName) && (
        <DataTypeProvider
          for={[ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.ASSIGNED_BY.columnName]}
          formatterComponent={renderAssignedBy}
        />
      )}
      {payerAccountsColumns.find(
        (c) => c === ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName,
      ) && (
        <DataTypeProvider
          for={[ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS.REMOVE_LINKED_ACCOUNT.columnName]}
          formatterComponent={renderRemoveButton}
        />
      )}
      <TableWrapper
        virtual
        noDataCellComponent={({ colSpan }) => NoAccounts({ colSpan, type: ACCOUNT_TYPES.PAYER_ACCOUNT })}
        rowComponent={renderPayerAccountTableRow}
        height="fit-content"
      />
      <TableSelection showSelectAll cellComponent={renderCustomSelectionCell} selectionColumnWidth={20} />
      <TableRowDetail contentComponent={renderLinkedAccounts} toggleCellComponent={CustomToggleCell} />
    </Grid>
  );
};

PayerAccountsTable.propTypes = {
  accountsData: PropTypes.array.isRequired,
};

export default PayerAccountsTable;
