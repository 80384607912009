import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import { FilterTypes } from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { PAYMENT_OPTIONS, TERMS } from 'commitment/containers/spAnalyzerNew/utils/consts';
import useSpAnalyzer from "commitment/containers/spAnalyzerNew/hooks/useSpAnalyzer.jsx";
import {useRootStore} from "app/contexts/RootStoreContext.jsx";
import {usePreferences} from "users/hooks/react-query/usePreferences.js";

const SpAnalyzerContext = createContext();
export const SpAnalyzerProvider = ({ children }) => {
  const paymentOptions = PAYMENT_OPTIONS;
  const terms = TERMS;
    const { usersStore } = useRootStore();
  const [planDataByKey, setPlanDataByKey] = useState({});
  const [daysToCheck, setDaysToCheck] = useState(null);
  const [coverage, setCoverage] = useState(null);
  const [hourlyCommitment, setHourlyCommitment] = useState(null);
  const [modifiedPlanKeyHourlyCommitment, setModifiedPlanKeyHourlyCommitment] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [filterStatusType, setFilterStatusType] = useState({
    [AwsCommonFields.LINKED_ACCOUNT_ID]: FilterTypes.INCLUDE,
  });
  const { fetchSavingsPlansAnalyzerData } = useSpAnalyzer();
  const { fetchPreferences } = usePreferences();
  const { data: preferencesData, isLoading: isPreferencesLoading } = fetchPreferences(usersStore.currDispUserAccountKey);
  const { data: recommendationPlanData, isLoading, refetch, isFetching } = fetchSavingsPlansAnalyzerData(
    selectedOptions,
    daysToCheck,
    filterStatusType,
    paymentOptions,
    terms,
    coverage,
    hourlyCommitment,
  );

  const preferences = useMemo(() => {
      if(!preferencesData) {
          return {};
      }
      const savingPreferences = preferencesData.find((pref) => pref.preferenceKey === 'ec2-savings-plans');
      return savingPreferences?.preferences || {};
  }, [preferencesData]);

  useEffect(() => {
     if (preferences) {
        setCoverage(preferences.coveragePercentage * 100);
        setDaysToCheck(preferences.daysToCheck);
     }
  }, [preferences]);

  return (
    <SpAnalyzerContext.Provider
      value={{
        filterStatusType,
        recommendationPlanData,
        setFilterStatusType,
        selectedOptions,
        setSelectedOptions,
        daysToCheck,
        setDaysToCheck,
        coverage,
        setCoverage,
        paymentOptions,
        terms,
        hourlyCommitment,
        setHourlyCommitment,
        modifiedPlanKeyHourlyCommitment,
        setModifiedPlanKeyHourlyCommitment,
        preferences,
        planDataByKey,
        setPlanDataByKey,
        isPreferencesLoading,
        isLoading,
        refetch,
        isFetching
      }}
    >
      {children}
    </SpAnalyzerContext.Provider>
  );
};

SpAnalyzerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSpAnalyzerContext = () => useContext(SpAnalyzerContext);
