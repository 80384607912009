import React from 'react';
import PropType from 'prop-types';
import { ReactComponent as NoRoles } from 'users/containers/Organization/Roles/img/no-roles.svg';

import styles from './RolesNoData.module.scss';

const RolesNoData = ({ colSpan, rowSpan }) => {
  return (
    <td colSpan={colSpan} rowSpan={rowSpan} className="border-bottom-0 border-start-0 border-end-0">
      <div className={styles.rolesEmptyState}>
        <NoRoles className={styles.svgImage} />
        <div className={styles.emptyStateText}>
          <span className={styles.mainText}>No Roles found</span>
          <span className={styles.text}>Refine your filters to find the role you are looking for</span>
        </div>
      </div>
    </td>
  );
};

RolesNoData.propTypes = {
  colSpan: PropType.number,
};

export default RolesNoData;
