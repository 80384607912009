import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import styles from './RemoveButton.module.scss';

const RemoveButton = ({ showRemoveComponent, isMultiple, selectedCounter, removeClicked }) => {
  const handleRemovedClicked = (e) => {
    e.stopPropagation();
    removeClicked();
  };

  return showRemoveComponent ? (
    <Button
      className={{ button: styles.removeRolesContainer, buttonContent: styles.content }}
      isTextButton
      icon={() => <GenerateIcon iconName={ICONS.circleMinus.name} className={styles.icon} />}
      onClick={handleRemovedClicked}
      text={isMultiple ? `Remove Selected (${selectedCounter})` : 'Remove'}
    />
  ) : (
    <div />
  );
};

RemoveButton.propTypes = {
  showRemoveComponent: PropTypes.bool,
  isMultiple: PropTypes.bool,
  selectedCounter: PropTypes.number,
  removeClicked: PropTypes.func.isRequired,
};

RemoveButton.defaultProps = {
  showRemoveComponent: true,
  isMultiple: false,
  selectedCounter: 0,
};

export default RemoveButton;
